<template>
    <div class="MuseumBox">
        <div class="BGBox">
            <img src="../../assets/DigitalMuseum/bg3.jpg">
            <img class="BG2" src="../../assets/DigitalMuseum/bg2.jpg">
            <img class="BG1" src="../../assets/DigitalMuseum/bg1.jpg">
        </div>

        <div class="Door" @click="open" v-if="door">
            <img src="../../assets/DigitalMuseum/jt1.png">
            <img src="../../assets/DigitalMuseum/jt2.png">
            <img src="../../assets/DigitalMuseum/jt3.png">
        </div>
        <div class="JGBox GZBox" @click="openTips">
            <img class="Name Name1" src="../../assets/DigitalMuseum/GZJGG1.png">
            <img class="Name Name2" src="../../assets/DigitalMuseum/GZJGG2.png">
            <img class="JianTou" src="../../assets/DigitalMuseum/JGJT.png">
        </div>
        <div class="TGBox GZBox" @click="$router.push('/digtalMuseum')">
            <img class="Name1 Name" src="../../assets/DigitalMuseum/GZTGG1.png">
            <img class="Name2 Name" src="../../assets/DigitalMuseum/GZTGG2.png">
            <img class="JianTou" src="../../assets/DigitalMuseum/TGJT.png">
        </div>
        <div class="XKBox GZBox" @click="openTips">
            <img class="Name1 Name " src="../../assets/DigitalMuseum/GZXKG1.png">
            <img class="Name2 Name " src="../../assets/DigitalMuseum/GZXKG2.png">
            <img class="JianTou" src="../../assets/DigitalMuseum/XKJT.png">
        </div>
        <div class="QKBox GZBox" @click="openTips">
            <img class="Name1 Name " src="../../assets/DigitalMuseum/GZQKG1.png">
            <img class="Name2 Name " src="../../assets/DigitalMuseum/GZQKG2.png">
            <img class="JianTou" src="../../assets/DigitalMuseum/QKJT.png">
        </div>
        <div class="STSBox GZBox" @click="openTips">
            <img class="Name1 Name " src="../../assets/DigitalMuseum/GZSTS1.png">
            <img class="Name2 Name " src="../../assets/DigitalMuseum/GZSTS2.png">
            <img class="JianTou" src="../../assets/DigitalMuseum/STSJT.png">
        </div>
        <div class="CKBox GZBox" @click="$router.push('/')">
            <img class="JianTou" src="../../assets/DigitalMuseum/ChuKou.png">
        </div>
    </div>
    <TiShiBox ref="childRef"></TiShiBox>

</template>
<script>
import $ from 'jquery'
import TiShiBox from '../Public/TiShiBox.vue'
import { ElLoading } from 'element-plus'

export default {
    components: { TiShiBox },
    data () {
        return {
            door: true,
        }
    },
    mounted () {
        const loading = ElLoading.service({
            lock: true,
            text: '加载中...',
            background: 'rgba(0, 0, 0, 0.8)',
        })
        setTimeout(() => {
            loading.close()
        }, 2000)
    },
    methods: {
        open () {

            $('.BG1').fadeOut(2000, 'swing', () => {
                this.door = false
                $('.BG2').fadeOut(2000, 'swing', () => {
                    $('.GZBox').css('display', 'block')
                })
            })
        },
        openTips () {
            this.$refs.childRef.ChuFa()
        }
    }
}
</script>
<style lang="less" scoped>
.MuseumBox {
    width: 100vw;
    height: 100vh;
    // background: url('../../assets/DigitalMuseum/bg1.jpg') no-repeat;
    // background-size: 100% 100%;
    position: relative;
    perspective: 800px;
    .BGBox {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        img {
            width: 100%;
            height: 100%;
            position: absolute;
        }
    }
    .Door {
        background: transparent;
        width: 10vw;
        height: 25vh;
        position: absolute;
        left: 45%;
        bottom: 16vh;
        cursor: pointer;
        display: flex;
        img {
            display: block;
            width: 3vw;
            height: 4vh;
            position: absolute;
            bottom: -2vh;
            left: 0;

            animation-name: move; //动画名字
            animation-duration: 3s; //持续时间
            animation-iteration-count: infinite; //循环次数 infinite为无限次循环（默认值为1）
        }
        img:nth-child(2) {
            left: 33%;
        }

        img:nth-child(3) {
            left: 66%;
        }
    }
    .GZBox {
        width: 10vw;
        height: 40vh;
        position: absolute;
        cursor: pointer;
        display: none;
        .Name {
            width: 40%;
            position: relative;
            top: 15vh;
            left: 2vw;
        }
        .Name2 {
            display: none;
        }
        .JianTou {
            width: 3vw;
            height: 4vh;
            position: absolute;
            bottom: -2vh;
            left: 3vw;
            animation-name: move; //动画名字
            animation-duration: 3s; //持续时间
            animation-iteration-count: infinite; //循环次数 infinite为无限次循环（默认值为1）
        }
    }
    .JGBox:hover,
    .TGBox:hover,
    .XKBox:hover,
    .QKBox:hover,
    .STSBox:hover {
        .Name1 {
            display: none;
        }
        .Name2 {
            display: block;
        }
    }
    .JGBox {
        bottom: 20vh;
        left: 4vw;
        .JianTou {
            bottom: -5vh;
        }
    }
    .TGBox {
        bottom: 22vh;
        left: 40vw;
        .JianTou {
            left: 4vw;
        }
        .Name2 {
            width: 36%;
        }
    }
    .XKBox {
        bottom: 22vh;
        left: 55vw;
        .Name {
            width: 32%;
            left: 3vw;
        }
    }
    .QKBox {
        width: 12vw;
        .Name {
            width: 24%;
            left: 4vw;
        }
        bottom: 22vh;
        left: 70vw;
    }
    .STSBox {
        bottom: 22vh;
        left: 88vw;
        .Name {
            top: 12vh;
            width: 30%;
            left: 3vw;
        }
    }
    .CKBox {
        width: 15vw;
        bottom: 22vh;
        left: 20vw;
        .JianTou {
            width: 5vw;
            bottom: 5vh;
            left: 5vw;
        }
    }
}

.Open {
    animation-name: changeBg; //动画名字
    animation-duration: 6s; //持续时间
    animation-iteration-count: 1; //循环次数 infinite为无限次循环（默认值为1）
    animation-fill-mode: forwards; //默认值none，forwards回到关键帧，backwards回到第一帧
}

@keyframes changeBg {
    0% {
    }
    50% {
        background: url('../../assets/DigitalMuseum/bg2.jpg') no-repeat;
        background-size: 100% 100%;
    }
    100% {
        background: url('../../assets/DigitalMuseum/bg3.jpg') no-repeat;
        background-size: 100% 100%;
    }
}

@keyframes move {
    0% {
        transform: perspective(800px) translate3d(0, 0, 100px);
    }
    50% {
        transform: perspective(800px) translate3d(0, 20px, 0);
    }
    100% {
        transform: perspective(800px) translate3d(0, 40px, -100);
    }
}
</style>